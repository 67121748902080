<template>
  <div class="common-affix">
    <el-backtop :visibility-height="50" :bottom="30" :right="50">
      <div class="back flex items-center justify-center cursor-pointer">
        <img src="@/assets/img/icon-toTop.png" class="w-24 h-24" />
      </div>
    </el-backtop>
    <div class="btn">
      <div
        class="flex flex-col items-center cursor-pointer"
        @click="router.push('/dashboard')"
      >
        <img src="@/assets/img/icon-home.png" class="w-24 h-24 mb-4" />
        <span class="fs-14">首页</span>
      </div>
      <el-popover
        placement="left"
        :width="150"
        trigger="click"
        @show="getQrCode"
      >
        <template #reference>
          <div class="flex flex-col items-center cursor-pointer">
            <img src="@/assets/img/icon-qrCode.png" class="w-24 h-24 mb-4" />
            <span class="fs-14">App</span>
          </div>
        </template>
        <div class="flex flex-col items-center">
          <el-image
            src="https://build.hangxintong.cn/xuekaobao/dis/qrcode.png"
            style="width: 100px; height: 100px"
          ></el-image>
          <div class="fontSize-14">安卓版</div>
        </div>
        <div class="flex flex-col items-center">
          <el-image
            src="https://res.hangxintong.cn/HQT/appleApp.png"
            style="width: 100px; height: 100px"
          ></el-image>
          <div class="fontSize-14">苹果版</div>
        </div>
      </el-popover>

      <el-popover
        placement="left"
        :width="150"
        trigger="click"
        @show="getQrCode"
      >
        <template #reference>
          <div class="flex flex-col items-center cursor-pointer">
            <img src="@/assets/img/icon-H5.png" class="w-24 h-24 mb-4" />
            <span class="fs-14">H5</span>
          </div>
        </template>
        <div
          v-loading="qrCodeLoading"
          class="flex flex-col justify-center items-center"
        >
          <div class="h-120">
            <img v-if="h5QrCode" :src="h5QrCode" class="w-120 h-120" />
          </div>
          <div class="fs-12 color-#666 flex items-center"
            ><img
              src="@/assets/img/icon-weChat.png"
              class="w-24 h-24 mr-8"
            />微信扫码</div
          >
        </div>
      </el-popover>

      <el-popover
        placement="left"
        :width="150"
        trigger="click"
        @show="getQrCode"
      >
        <template #reference>
          <div class="flex flex-col items-center cursor-pointer">
            <img src="@/assets/img/icon-kf.png" class="w-24 h-24 mb-4" />
            <span class="fs-14">客服</span>
          </div>
        </template>
        <div class="flex flex-col justify-center items-center">
          <div class="h-120">
            <img
              src="https://res.hangxintong.cn/assoc688/web/imgs/qrcode2.png"
              class="w-120 h-120"
            />
          </div>
          <div class="fs-12 color-#666 flex items-center"
            ><img
              src="@/assets/img/icon-weChat.png"
              class="w-24 h-24 mr-8"
            />微信扫码</div
          >
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script setup>
import { useQrCode } from '@/hook/useQrCode'
import { useUserStore } from '@/store/modules/user'
const userStore = useUserStore()
const { createQrCode } = useQrCode()
const router = useRouter()
const h5QrCode = ref('')
const qrCodeLoading = ref(false)
// ====分享===
async function getQrCode() {
  qrCodeLoading.value = true
  let url = ''
  if (userStore.agentInfo && userStore.agentInfo.h5Domain) {
    // 代理商的H5
    url = 'http://' + userStore.agentInfo.h5Domain
  } else {
    url = import.meta.env.VITE_APP_XKBH5_URL // 行企通h5
  }
  const img = await createQrCode(url)
  h5QrCode.value = img
  qrCodeLoading.value = false
}
</script>

<style scoped lang="scss">
.common-affix {
  position: fixed;
  right: 50px;
  top: 50%;
  width: 64px;

  .back {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    background-color: rgba(255, 255, 255, 1);
    width: 64px;
    border-radius: 32px;
    padding: 24px 0;
    gap: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    color: rgba(102, 102, 102, 1);
  }
}
:deep(.el-backtop) {
  width: 64px;
  height: 64px;
}
</style>
