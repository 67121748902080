<template>
  <div class="w-1360 mx-auto my-20">
    <div
      class="flex justify-between items-center box-border px-24 py-13 color-#3D3D3D bg-#fff fs-16 mb-12"
    >
      学时打印
      <div>
        <el-button
          type="primary"
          class="w-169 mr-34"
          @click="commentPrint('test-id', trainRecord.trainName)"
          v-loading="bottloading"
        >
          下载
        </el-button>
        <el-button plain @click="router.go(-1)">返回</el-button>
      </div>
    </div>
    <div class="py-28 box-border bg-#fff" v-loading="loading" id="test-id">
      <div class="color-#333 fs-20 mx-auto w-800 myTable">
        <div>
          <div class="flex" style="display: flex; width: 800px">
            <div>
              <img :src="detalFaceImg" style="width: 89px; height: 130px" />
            </div>
            <el-descriptions
              :column="1"
              border
              size="small"
              class="w-100%"
              style="width: 100%"
            >
              <el-descriptions-item label="姓名" label-class-name="my-label">
                {{ detail.name || '--' }}
              </el-descriptions-item>
              <el-descriptions-item
                label="身份证号"
                label-class-name="my-label"
              >
                {{ detail.identity || '--' }}
              </el-descriptions-item>
              <el-descriptions-item
                label="证书编号"
                label-class-name="my-label"
              >
                {{
                  trainRecord.certificate && trainRecord.certificate.certNumber
                }}
              </el-descriptions-item>
              <el-descriptions-item
                label="岗位名称"
                label-class-name="my-label"
              >
                {{ trainRecord.trainName || '--' }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div class="mb-12 mt-24 fw-700 color-#333 fs-18 title">
          培训学习档案
        </div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="sectionTitle"
            label="学习内容科目"
            show-overflow-tooltip
          />
          <el-table-column prop="classHours" label="获得学时" width="200" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getClassHourDetailApi } from '@/api/home'
import { htmlToPDF } from '@/utils/html2pdf'

const router = useRouter()
const route = useRoute()
const tableData = ref([])
const detail = ref({})
const trainRecord = ref({})
const loading = ref(false)
const detalFaceImg = ref('')
const bottloading = ref(false)

onMounted(() => {
  getInfo()
})

// 获取详情
function getInfo() {
  loading.value = true
  getClassHourDetailApi(route.query.id)
    .then(res => {
      detail.value = res.data.employee
      let FaceImg = ''
      if (res.data.employee.realFaceImg) {
        FaceImg = res.data.employee.realFaceImg.split(
          'http://res.dev.hangxintong.cn/'
        )
        detalFaceImg.value = '/img-api' + '/' + FaceImg[1]
        console.log(detalFaceImg.value)
      } else {
        detalFaceImg.value = ''
      }
      trainRecord.value = res.data.trainRecord
      tableData.value = []
      if (res.data.trainRecord.trainStudyData.requiredCourseList) {
        res.data.trainRecord.trainStudyData.requiredCourseList.forEach(it => {
          tableData.value.push(it)
        })
      }
      if (res.data.trainRecord.trainStudyData.optionalCourseList) {
        res.data.trainRecord.trainStudyData.optionalCourseList.forEach(it => {
          tableData.value.push(it)
        })
      }
      loading.value = false
    })
    .catch(() => {
      loading.value = false
    })
}

function commentPrint(id, name) {
  bottloading.value = true
  htmlToPDF(id, name)
  bottloading.value = false
}
</script>

<style scoped lang="scss">
:deep(.my-label) {
  background: #f2f7fe !important;
  width: 200px !important;
}

:deep(.el-table__header-wrapper th.el-table__cell) {
  background: #f2f7fe;
}
</style>
