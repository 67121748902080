import http from '@/libs/http/index'

/**
 * @description 微信扫码 绑定手机号
 * @param {String} phone 手机号
 * @param {String} code 验证码
 * @param {String} employeeUserId 用户id
 */
export function bindPhoneApi({ phone, employeeUserId, code }) {
  return http.put('pc/user/bindPhone', {
    phone,
    employeeUserId,
    code,
  })
}
/**
 * @description 微信扫码 设置密码
 * @param {String} phone 手机号
 * @param {String} code 验证码
 * @param {String} employeeUserId 用户id
 */
export function setPwdApi({
  phone,
  employeeUserId,
  enterpriseName,
  // code,
  password,
}) {
  return http.put('pc/user/setPwd', {
    phone,
    employeeUserId,
    enterpriseName,
    // code,
    password,
  })
}
/**
 * @description 注册账号
 * @param {String} phone 手机号
 * @param {String} password 登录密码
 * @param {String}  enterpriseName 企业名称
 * @return {Object}
 */
export function registerApi({ phone, password, enterpriseName, code }) {
  return http.post('pc/user/register', {
    phone,
    password,
    enterpriseName,
    code,
  })
}
/**
 * @description 发送验证码
 * @param {String} phone 手机号
 * @param {String} scene 注册regist、登录login、修改密码mod_pwd、信息变更change,支付user_pay


 */
export function sendSmsApi({ phone, scene }) {
  console.log(scene)
  return http.post('common/send_sms', { phone, scene })
}
/**
 * @description 通过验证码获取token
 * @param {String} phone 手机号
 * @param {String} code 验证码
 * @return {Object}
 */
export function smsLoginApi({ phone, code }) {
  return http.post('pc/user/loginSms', { phone, code })
}

/**
 * @description 通过账号、密码获取token
 * @param {String} username 用户名
 * @param {String} password 密码
 * @return {Object}
 */
export function accountLoginApi({ username, password }) {
  return http.post('pc/user/login', { username, password })
}
/**
 * @description 通过微信openid获取token
 * @param {String} openId
 * @return {Object}
 */
export function weChatLoginApi(openId) {
  return http.post('h5/user/loginWx', { openId })
}
/**
 * @description 获取用户信息
 * @return {Object}
 */
export function userInfoApi() {
  return http.get('pc/user/getInfo')
}
/**
 * @description 找回密码
 * @param {String} phone 手机号
 * @param {String} code 验证码
 * @param {String} password 密码
 * @return {Object}
 */
export function forgetApi({ phone, code, password }) {
  return http.put('pc/user/findPwd', { phone, code, password })
}
/**
 * @description openId和手机号绑定
 * @param {String} phone 手机号
 * @param {String} code 验证码
 * @param {String} openId 密码
 * @return {Object}
 */
export function bindOpenIdAndPhoneApi({ phone, code, openId }) {
  return http.post('/h5/user/registerBind', { phone, code, openId })
}

/**
 * @description 域名换取代理商信息
 * @return {Object}
 */
export function postAgentInfoByHref(domain) {
  return http.post('pc/agent/getAgentInfo', { domain })
}
/**
 * @description 取消用户收藏列表
 * @param {String} id  列表id
 * @return {Object}
 */
export function cancelSelectListApi(id) {
  return http.delete(`pc/casualUserCollect/${id}`)
}
/**
 * @description 查询用户收藏列表
 * @param {String} pageNum  页码
 * @param {String} pageSize  每页数据
 * @return {Object}
 */
export function selectListApi({ pageNum, pageSize }) {
  return http.get('pc/casualUserCollect/pageList', {
    params: {
      pageNum,
      pageSize,
    },
  })
}
/**
 * @description 意见反馈
 * @param {String} content  反馈内容
 * @param {String} name  反馈内容
 * @param {String} phoneNumber  反馈内容
 * @return {Object}
 */
export function feedBackApi({ content, name, phoneNumber }) {
  return http.post('pc/feedback', { content, name, phoneNumber })
}

/**
 * @description 持有证书列表
 * @param {number} pageNum 页数
 * @param {number} pageSize 条数
 * @param {String} name 关键字
 * @return {Object}
 */
export function certListApi({ pageNum, pageSize, name }) {
  return http.get('pc/cert/pageList', {
    params: {
      pageNum,
      pageSize,
      name,
    },
  })
}
/**
 * @description 学时审验卡列表
 * @param {number} pageNum 页数
 * @param {number} pageSize 条数
 * @param {String} keyword 关键字
 *
 * @return {Object}
 */
export function certCardListApi({ pageNum, pageSize, keyword }) {
  return http.get('pc/studyLog/getCertLists', {
    params: {
      page: pageNum,
      size: pageSize,
      keyword,
    },
  })
}
/**
 * @description 查看证书详情
 * @param {number} id 证书id
 * @return {Object}
 */
export function certInfoApi(id) {
  return http.get('pc/cert/' + id)
}
/**
 * @description 报名申请列表
 * @param {String} pageNum  页码
 * @param {String} pageSize  每页数据
 * @param {String} searchValue  搜索关键字
 * @return {Object}
 */
export function applyListApi({ pageNum, pageSize, searchValue }) {
  return http.get('pc/trainProjectExam/enroll/pageList', {
    params: {
      pageNum,
      pageSize,
      searchValue,
    },
  })
}
/**
 * @description 取消报名申请
 * @param {String} id  报名申请id
 * @return {Object}
 */
export function cancelApplyApi(id) {
  return http.put(`pc/trainProjectExam/enroll/cancelApply/${id}`)
}
/**
 * @description 查询报名表单填写信息
 * @param {String} id  报名表单id
 * @return {Object}
 */
export function formRecordApi(id) {
  return http.get(`pc/trainProjectExam/enroll/${id}`)
}
/**
 * @description 更换用户头像
 * @param {String} face  用户头像url
 * @return {Object}
 */
export function updateUserFaceApi(face) {
  return http.put('pc/user/updateFace', {
    face,
  })
}
/**
 * @description 我的卡券
 * @param {Number} status 状态(0 未使用 1已使用)
 * @param {Number} pageNum
 * @param {Number} pageSize
 * @param {String} couponName 卡券名称
 * @return {Object}
 */
export function couponApi({ status, pageSize, pageNum, couponName }) {
  return http.get('pc/myCoupon/list', {
    params: {
      status,
      pageNum,
      pageSize,
      couponName,
    },
  })
}
/**
 * @description 抵扣券列表
 * @param {Number} orderId
 * @param {Number} pageNum
 * @param {Number} pageSize
 * @return {Object}
 */
export function deductionVoucherApi({ orderId, pageNum, pageSize }) {
  return http.get('pc/couponPool/queryCouponByCondition', {
    params: {
      orderId,
      pageNum,
      pageSize,
    },
  })
}

/**
 * @description 报名申请详情
 * @param {Number} id
 * @return {Object}
 */
export function applyListInfoApi(id) {
  return http.get(`pc/trainProjectExam/enroll/${id}`)
}
/**
 * @description 更换企业名称
 * @param {Number} enterpriseName 企业名称
 * @return {Object}
 */
export function changeCompanyNameApi(enterpriseName) {
  return http.put('pc/userCentre/replaceCompanyName', {
    enterpriseName,
  })
}
/**
 * @description 完善个人信息
 * @param {String} education 学历
 * @param {String} professionalTitle 专业职称
 * @param {String} address 地址
 * @return {Object}
 */
export function supplementaryInformationApi({
  address,
  professionalTitle,
  education,
}) {
  return http.put('pc/userCentre/suppleInfo', {
    education,
    professionalTitle,
    address,
  })
}
