<template>
  <div>
    <div class="title flex justify-between items-center"
    >注册账号
      <el-button type="primary" link @click="emit('changeLoginType', 'pwd')"
      >返回登录
      </el-button
      >
    </div>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" size="large">
      <el-form-item prop="phone">
        <el-input
            v-model="ruleForm.phone"
            placeholder="请输入手机号码"
            autocomplete="off"
            maxlength="11"
            prefix-icon="iphone"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            v-model="ruleForm.password"
            type="password"
            show-password
            autocomplete="off"
            size="large"
            placeholder="请输入6-15位数字或字母密码"
            maxlength="15"
            prefix-icon="Lock"
        />
      </el-form-item>
      <el-form-item prop="code">
        <el-input
            v-model="ruleForm.code"
            autocomplete="off"
            placeholder="请输入验证码"
            maxlength="6"
            prefix-icon="Lock"
        >
          <template #suffix>
            <el-button
                v-if="time == 60"
                type="primary"
                :loading="btnLoading"
                link
                @click="getCode"
            >获取验证码
            </el-button
            >
            <span v-else class="color-#333 fs-14"
            >{{ time }}s后重新获验证码</span
            >
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="enterpriseName">
        <el-input
            v-model="ruleForm.enterpriseName"
            placeholder="请输入企业名称"
            autocomplete="off"
            maxlength="50"
            prefix-icon="OfficeBuilding"
        />
      </el-form-item>
    </el-form>
    <el-button
        class="w-full"
        color="#176EF7"
        type="primary"
        size="large"
        @click="submitForm(ruleFormRef)"
    >注册
    </el-button
    >
    <div class="flex justify-center items-center mt-20 text-d">
      <el-checkbox v-model="agree" label="我已阅读并同意" size="large"/>
      <a
          class="color-#176EF7"
          style="text-decoration: none"
          href="javascript:"
          @click="dialogAFun('A')"
      >《用户协议》</a
      >和
      <a
          class="color-#176EF7"
          style="text-decoration: none"
          href="javascript:"
          @click="dialogAFun('B')"
      >《隐私政策》</a
      >
    </div>
  </div>
</template>

<script setup>
import {isMobile, isPassword, isCode} from '@/utils/validate'
import {registerApi, sendSmsApi} from '@/api/user.js'
import {useUserStore} from '@/store/modules/user'

const emit = defineEmits(['changeLoginType', 'dropDownValueChange'])
const userStore = useUserStore()
const agree = ref(false)
const dialogA = ref(false)
const dialogB = ref(false)
const ruleFormRef = ref()

function dialogAFun(type) {
  emit('dropDownValueChange', type)
}

// 表单数据
const ruleForm = ref({
  phone: '',
  password: '',
  enterpriseName: '',
  code: '',
  scene: 'app_login',
})
// 表单校验规则
const rules = {
  phone: [
    {
      required: true,
      message: '请输入手机号码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isMobile(value)
      },
      trigger: 'blur',
      message: '请输入正确的手机号码',
    },
  ],
  code: [
    {
      required: true,
      message: '请输入短信验证码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isCode(value)
      },
      trigger: 'blur',
      message: '请输入6位验证码',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isPassword(value)
      },
      trigger: 'blur',
      message: '请输入6-15位密码',
    },
  ],
  enterpriseName: [
    {
      required: true,
      message: '请输入企业名称',
      trigger: 'blur',
    },
  ],
}
const time = ref(60)
const timer = ref(null)
const btnLoading = ref(false)

function getCode() {
  if (timer.value) return
  if (!ruleFormRef.value) return
  try {
    ruleFormRef.value.validateField('phone', async errorsRes => {
      if (!errorsRes) return
      btnLoading.value = true
      ruleForm.value.scene = 'regist'
      await sendSmsApi(ruleForm.value)
      startCountdown()
    })
  } catch (error) {
    btnLoading.value = false
  }
}

// 倒计时
function startCountdown() {
  timer.value = setInterval(function () {
    time.value--
    if (time.value === 0) {
      clearInterval(timer.value)
      timer.value = null
      time.value = 60
      btnLoading.value = false
    }
  }, 1000)
}

// 注册
const submitForm = formEl => {
  if (!formEl) return
  formEl.validate(async valid => {
    if (valid) {
      if (!agree.value) {
        ElMessage.error('请勾选同意用户协议和隐私政策')
        return
      }
      try {
        console.log('测试')
        const {data} = await registerApi(ruleForm.value)
        userStore.setToken(data.token)
        userStore.setShowLogin(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      return false
    }
  })
}
</script>

<style scoped lang="scss">
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
