<template>
  <div class="w-1360 user-layout mx-auto flex pt-18">
    <div class="w-174 bg-white">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        popper-effect="light"
        menu-trigger="click"
      >
        <el-menu-item index="1" @click="router.push('/user/userInfo')">
          <span class="iconfont icon-user mr-10"></span>
          <span>个人信息</span>
        </el-menu-item>
        <el-menu-item index="2" @click="router.push('/user/registration')">
          <span class="iconfont icon-menu_bmlb mr-10"></span>
          <span>报名申请</span>
        </el-menu-item>
        <el-sub-menu index="3">
          <template #title>
            <span class="iconfont icon-dingdan mr-10"></span>
            <span>我的订单</span>
          </template>
          <el-menu-item index="3-1" @click="router.push('/user/unpaid')"
            >待支付</el-menu-item
          >
          <el-menu-item index="3-2" @click="router.push('/user/paid')"
            >已完成</el-menu-item
          >
          <el-menu-item index="3-3" @click="router.push('/user/refund')"
            >已退款</el-menu-item
          >
        </el-sub-menu>
        <el-menu-item index="4" @click="router.push('/user/myCardRoll')">
          <span class="iconfont icon-kaquan-fill mr-10"></span>
          <span>我的卡券</span>
        </el-menu-item>
        <el-menu-item index="5" @click="router.push('/user/myInvoice')">
          <span class="iconfont icon-fapiaochangliangguanli mr-10"></span>
          <span>我的发票</span>
        </el-menu-item>
        <el-menu-item index="6" @click="router.push('/user/myCollection')">
          <span class="iconfont icon-shoucang mr-10"></span>
          <span>我的收藏</span>
        </el-menu-item>
        <el-sub-menu index="7">
          <template #title>
            <span class="iconfont icon-zizhizhengshu mr-10"></span>
            <span>我的证书</span>
          </template>
          <el-menu-item index="7-1" @click="router.push('/user/myCert')"
            >持有证书</el-menu-item
          >
          <el-menu-item index="7-2" @click="router.push('/user/myVerCard')"
            >学时审验卡</el-menu-item
          >
        </el-sub-menu>
        <el-menu-item index="8" @click="router.push('/user/feedback')">
          <span class="iconfont icon-zhengshu mr-10"></span>
          <span>意见反馈</span>
        </el-menu-item>
        <el-menu-item index="9" @click="router.push('/user/support')">
          <span class="iconfont icon-Fill mr-10"></span>
          <span>技术支持</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="flex-1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
const router = useRouter()
const defaultActive = ref('1')
watch(
  () => router.currentRoute.value,
  newValue => {
    switch (newValue.path) {
      case '/user/userInfo':
        defaultActive.value = '1'
        break
      case '/user/registration/registrationInfo':
      case '/user/registration':
        defaultActive.value = '2'
        break
      case '/user/unpaid':
        defaultActive.value = '3-1'
        break
      case '/user/paid/orderInfo':
      case '/user/paid/exchange':
      case '/user/paid':
        defaultActive.value = '3-2'
        break
      case '/user/refund':
        defaultActive.value = '3-3'
        break
      case '/user/myCardRoll':
        defaultActive.value = '4'
        break
      case '/user/myInvoice':
        defaultActive.value = '5'
        break
      case '/user/myCollection':
        defaultActive.value = '6'
        break
      case '/user/myCert/myCertInfo':
      case '/user/myCert':
        defaultActive.value = '7-1'
        break
      case '/user/myVerCard':
        defaultActive.value = '7-2'
        break
      case '/user/feedback':
        defaultActive.value = '8'
        break
      case '/user/support':
        defaultActive.value = '9'
        break
    }
  },
  { immediate: true }
)
</script>

<style scoped lang="scss">
.user-layout {
  min-height: calc(100vh - 276px);
}
:deep(.el-menu--vertical .el-menu-item:hover) {
  background-color: transparent !important;
}
:deep(.el-menu) {
  border-right: 0;
}
</style>
