<template>
  <div class="w-1360 mx-auto my-20">
    <div
      class="flex justify-between items-center box-border px-24 py-13 color-#3D3D3D bg-#fff fs-16 mb-12"
    >
      学时打印
      <el-button plain @click="router.go(-1)">返回</el-button>
    </div>
    <div class="py-20 px-24 box-border bg-#fff" v-loading="loading">
      <el-table :data="tableData" border style="width: 100%" :loading="loading">
        <el-table-column
          prop="trainRecord.trainName"
          label="项目名称"
          show-overflow-tooltip
        />
        <el-table-column
          prop="trainRecord.trainStudyData.classHours"
          label="获得总学时"
          width="180"
        />
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="preview(scope.row)"
              >预览</el-button
            >
            <!-- <el-divider direction="vertical" />
            <el-button link type="primary" size="small">下载</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center my-29">
        <el-pagination
          :page-size="queryForm.pageSize"
          :current-page="queryForm.pageNum"
          hide-on-single-page
          background
          layout="prev, pager, next, total"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { getClassHourListApi } from '@/api/home'
const router = useRouter()
const tableData = ref([])
const loading = ref(false)
const queryForm = reactive({
  pageSize: 15,
  pageNum: 1,
})
const total = ref(0)
function handleCurrentChange(val) {
  queryForm.pageNum = val
  getList()
}
onMounted(() => {
  getList()
})
// 预览详情
function preview(item) {
  router.push('/classHourPrint?id=' + item.trainRecord.id)
}
function getList() {
  loading.value = true
  getClassHourListApi(queryForm)
    .then(res => {
      tableData.value = res.data.list || []
      total.value = res.data.total
    })
    .finally(() => {
      loading.value = false
    })
}
</script>

<style scoped lang="scss">
:deep(.my-label) {
  background: #f2f7fe !important;
  width: 200px !important;
}
:deep(.el-table__header-wrapper th.el-table__cell) {
  background: #f2f7fe;
}
</style>
