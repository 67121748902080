import QRCode from 'qrcode'
export const useQrCode = url => {
  // 生成二维码
  function createQrCode(url) {
    console.log(url)
    return new Promise(async (resolve, reject) => {
      if (!url) return reject(new Error('请传入url'))
      try {
        const qrCodeUrl = await QRCode.toDataURL(url)
        return resolve(qrCodeUrl)
      } catch (error) {
        return reject(error)
      }
    })
  }
  return {
    createQrCode,
  }
}
