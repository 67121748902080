export default {
    install(Vue) {
        Vue.mixin({
            mounted() {
                var _hmt = _hmt || [];
                (function() {
                    var hm = document.createElement("script");
                    hm.src = "https://hm.baidu.com/hm.js?e0ab2344371fae62f15768b9847643bf";
                    var s = document.getElementsByTagName("script")[0];
                    s.parentNode.insertBefore(hm, s);
                })();
            }
        });
    }
};