<template>
  <div class="login-mask">
    <div class="login-box">
      <div class="text-center mb-42">
        <img src="@/assets/img/hqt-text-logo.png" class="w-136 h-48" />
      </div>
      <div class="close" @click="userStore.setShowLogin(false)">
        <el-icon size="24" color="#999"><CloseBold /></el-icon>
      </div>
      <PwdLogin v-if="loginType === 'pwd'" @change-login-type="changeType" />
      <!-- 验证码登录 -->
      <CodeLogin v-if="loginType === 'code'" @change-login-type="changeType" />
      <!-- 微信登录 -->
      <WeChatLogin
        v-if="loginType === 'weChat'"
        @change-login-type="changeType"
      />
      <!-- 绑定手机号 -->
      <BindMobile
        v-if="loginType === 'bindMobile'"
        :user-id="userId"
        :set-pwd="setPwd"
        @change-login-type="changeType"
      />
      <!-- 忘记密码 -->
      <Forget v-if="loginType === 'forget'" @change-login-type="changeType" />
      <!-- 注册账号 -->
      <Register
        v-if="loginType === 'register'"
        @change-login-type="changeType"
      />
    </div>
  </div>
</template>
<script setup>
import { useUserStore } from '@/store/modules/user'

const userStore = useUserStore()
const props = defineProps({
  type: {
    type: String,
    default: 'weChat',
  },
})
const userId = ref(null)
const setPwd = ref(false)
onMounted(() => {
  loginType.value = 'weChat'
  loginType.value = props.type
  console.log(loginType.value)
})

const loginType = ref('weChat')
function changeType(type, otherData, userPhone) {
  console.log(11111111)
  console.log(type, otherData, userPhone)

  switch (type) {
    case 'pwd':
      loginType.value = 'pwd'
      break
    case 'code':
      loginType.value = 'code'
      break
    case 'forget':
      loginType.value = 'forget'
      break
    case 'weChat':
      loginType.value = 'weChat'
      break
    case 'bindMobile':
      if (otherData) {
        userId.value = otherData
        console.log(otherData)
      }
      if (userPhone) {
        setPwd.value = userPhone
      }
      loginType.value = 'bindMobile'
      break
    case 'register':
      loginType.value = 'register'
      break
    default:
      loginType.value = 'weChat'
      break
  }
}
</script>

<style scoped lang="scss">
.login-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .login-box {
    width: 520px;
    height: 580px;
    background: #fff;
    // border: 1px solid rgba(187, 187, 187, 1);
    padding: 52px 40px 40px;
    box-sizing: border-box;
    position: relative;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    :deep .el-form-item {
      margin-bottom: 28px;
    }
  }
}
</style>
