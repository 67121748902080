<template>
  <!-- 鸿德文理通用布局 -->
  <div class="common-layout" v-if="userStore.agentInfo.id == hongDeAgentId">
    <hongDe-header />
    <common-main />
    <hongDe-footer />
  </div>
  <!-- 建造是继续教育平台布局 -->
  <div class="common-layout" v-else-if="userStore.agentInfo.id == jzsAgentId">
    <JZS-header />
    <common-main />
    <JZS-footer />
  </div>
  <!-- 默认通用布局 -->
  <div class="common-layout" v-else>
    <common-header />
    <common-main />
    <common-footer />
  </div>
</template>

<script setup>
import { useUserStore } from '@/store/modules/user'
const userStore = useUserStore()
const hongDeAgentId = import.meta.env.VITE_APP_AGENT_ID_HONGDE
const jzsAgentId = import.meta.env.VITE_APP_AGENT_ID_JZS // 建造师继续教育平台的agentId
</script>

<style scoped lang="scss">
.common-layout {
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(241, 244, 255, 1);
}
</style>
