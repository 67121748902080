import request from '@/libs/http/index'

//表单详情
export function getElementApi(formId) {
  return request.get(`pc/custom/form/getElement/${formId}`)
}

/**
 表单提交
 */
export function postFormData(data) {
  return request.post(`pc/trainProjectExam/enroll`, data)
}
