<template>
  <div class="w-1360 mx-auto">
    <!-- 标签 -->
    <div
      v-if="allTag.length"
      class="flex justify-between bg-#fff mt-20 mb-12 py-20 px-24 gap-x-10"
    >
      <div class="flex justify-start gap-16 flex-wrap">
        <div
          v-for="(tag, index) in allTag"
          :key="index"
          class="class-item"
          :class="
            queryForm.tagIdList.find(item => item == tag.id) ? 'active-tag' : ''
          "
          @click="selTag(tag)"
        >
          {{ tag.tagName }}
        </div>
      </div>
      <el-button plain @click="resetTag">重置</el-button>
    </div>
    <!-- 筛选 -->
    <div class="py-13 px-24 bg-#fff flex justify-between items-center">
      <div class="flex items-center">
        排序：
        <el-select
          v-model="queryForm.orderByCreateTime"
          placeholder="排序"
          style="width: 150px; margin-right: 80px"
          @change="handleOrder"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        筛选：
        <el-cascader
          :options="categoryList"
          :show-all-levels="false"
          v-model="queryForm.categoryId"
          :props="props"
          clearable
          @change="handleCategory"
        />
      </div>
      <div>
        <el-input
          v-model="queryForm.name"
          style="width: 360px"
          placeholder="搜索关键字"
          clearable
          @clear="clearHandler"
        >
        </el-input>
        <el-button type="primary" @click="getList">搜索</el-button>
      </div>
    </div>
    <div class="fs-26 color-#3E454D my-20">{{ cateName || '全部课程' }}</div>
    <div
      class="flex justify-start flex-wrap gap-x-16 gap-y-20"
      v-loading="loading"
    >
      <div
        v-for="(item, index) in courseList"
        :key="index"
        class="w-328 h-328 flex flex-col cursor-pointer"
        @click="toInfo(item)"
      >
        <img
          :src="
            item.cover ||
            'https://res.hangxintong.cn/uniXkb/backgroundImage/course_background.png'
          "
          class="w-full h-184"
        />
        <div
          class="flex flex-1 flex-col justify-center items-start py-16 px-12 bg-#fff"
        >
          <div class="fs-16 h-40 color-#3E454D mb-12 text-ellipsis-2">
            {{ item.name }}
          </div>
          <div class="h-27 mb-16 flex items-center gap-x-10 gap-y-6">
            <div
              v-if="item.tagList.length > 0"
              class="fs-14 px-14 py-4 borderRadius-20 flex-shrink-0 text-ellipsis-1"
              :style="{
                'max-width': '150px',
                color: '#fff',
                'background-color': item.tagList[0].tagColor,
              }"
              >{{ item.tagList[0].tagName }}
            </div>
            <el-popover
              v-if="item.tagList.length > 1"
              placement="top-start"
              trigger="hover"
              :width="200"
            >
              <template #reference>
                <el-icon>
                  <MoreFilled />
                </el-icon>
              </template>
              <div class="flex flex-col justify-between items-start">
                <div
                  v-for="(tag, ind) in item.tagList.slice(1)"
                  :key="ind"
                  class="fs-14 px-14 py-4 borderRadius-20 mb-8 text-ellipsis-1"
                  :style="{
                    'max-width': '150px',
                    color: '#fff',
                    'background-color': tag.tagColor,
                  }"
                  >{{ tag.tagName }}
                </div>
              </div>
            </el-popover>
          </div>
          <div class="fs-12 color-#999999">
            价格：
            <span class="fs-18 fontWeight-700 color-#FF0000">
              <span class="fs-12">￥</span>
              {{ item.price }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <el-empty
      v-if="!courseList.length"
      description="暂无课程"
      :image-size="100"
      image="https://res.hangxintong.cn/uniXkb/status/s3.png"
    />
    <div class="flex justify-center my-29">
      <el-pagination
        hide-on-single-page
        v-model:page-size="queryForm.pageSize"
        v-model:current-page="queryForm.pageNum"
        background
        layout="prev, pager, next, total"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import {
  courseListApi,
  getTagListApi,
  getTrainTreeApi,
  courseFilterListApi,
} from '@/api/course'
import { useUserStore } from '@/store/modules/user'

const props = {
  multiple: false,
  checkStrictly: true,
  emitPath: false,
}
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const total = ref(0)
const queryForm = reactive({
  pageNum: 1,
  pageSize: 16,
  name: '',
  orderByCreateTime: 'desc', // 默认倒序  asc  desc
  categoryId: '',
  tagIdList: [],
})
const loading = ref(false)
const options = [
  {
    value: 'asc',
    label: '时间从旧到新',
  },
  {
    value: 'desc',
    label: '时间从新到旧',
  },
]
const cateName = ref('')
const categoryList = ref([]) // 父级分类
const allTag = ref([]) // 所有的标签
// 课程列表
const courseList = ref([])
onMounted(() => {
  // 代理商下单，需带上agentId
  if (userStore.agentInfo) {
    queryForm.agentId = userStore.agentInfo.id
  }
  if (route.query.categoryId) {
    queryForm.categoryId = route.query.categoryId
  }
  if (route.query.pageNum) {
    queryForm.pageNum = +route.query.pageNum
  }
  if (route.query.name) {
    queryForm.name = route.query.name
  }
  if (route.query.orderByCreateTime) {
    queryForm.orderByCreateTime = route.query.orderByCreateTime
  }
  getList()
  getAllTag()
  getTrainTree()
})
function clearHandler() {
  queryForm.name = ''
  getList()
}
// 分类树
async function getTrainTree() {
  // getTrainTreeApi().then(res => {
  //   categoryList.value = res.data
  // })
  try {
    const agentId = userStore.agentInfo.id
    const { data } = await courseFilterListApi(agentId)
    categoryList.value = data
  } catch (error) {
    ElMessage({ message: JSON.stringify(error), type: 'error' })
  }
}

function handleCategory(data) {
  // console.log(data)
  // console.log(categoryList.value)
  if (data == null) {
    queryForm.categoryId = ''
  }
  cateName.value = guigui(categoryList.value, data == null ? '' : data)
  queryForm.pageNum = 1
  let newUrl =
    window.location.pathname +
    '?pageNum=' +
    queryForm.pageNum +
    '&name=' +
    queryForm.name +
    '&categoryId=' +
    queryForm.categoryId +
    '&orderByCreateTime=' +
    queryForm.orderByCreateTime

  window.history.pushState(
    { categoryId: queryForm.categoryId },
    'categoryId' + queryForm.categoryId,
    newUrl
  )
  getList()
}

function guigui(data, id) {
  // console.log(data, id)
  for (let i = 0; i < data.length; i++) {
    if (data[i].id == id) {
      return data[i].label
    }
    if (data[i].children && data[i].children.length > 0) {
      return guigui(data[i].children, id)
    }
  }
}

// function handleTree(data, id, parentId, children) {
//   let config = {
//     id: id || 'id',
//     parentId: parentId || 'parentId',
//     childrenList: children || 'children',
//   }

//   var childrenListMap = {}
//   var nodeIds = {}
//   var tree = []

//   for (let d of data) {
//     let parentId = d[config.parentId]
//     if (childrenListMap[parentId] == null) {
//       childrenListMap[parentId] = []
//     }
//     nodeIds[d[config.id]] = d
//     childrenListMap[parentId].push(d)
//   }

//   for (let d of data) {
//     let parentId = d[config.parentId]
//     if (nodeIds[parentId] == null) {
//       tree.push(d)
//     }
//   }

//   for (let t of tree) {
//     adaptToChildrenList(t)
//   }

//   function adaptToChildrenList(o) {
//     if (childrenListMap[o[config.id]] !== null) {
//       o[config.childrenList] = childrenListMap[o[config.id]]
//     }
//     if (o[config.childrenList]) {
//       for (let c of o[config.childrenList]) {
//         adaptToChildrenList(c)
//       }
//     }
//   }
//   return tree
// }

// 获取所有的标签
function getAllTag() {
  getTagListApi(queryForm.agentId).then(res => {
    allTag.value = res.data
  })
}

// 选择标签
function selTag(tag) {
  let index = queryForm.tagIdList.findIndex(id => id == tag.id)
  if (index > -1) {
    queryForm.tagIdList.splice(index, 1) // 已存在的标签 移除掉
  } else {
    queryForm.tagIdList.push(tag.id) // 添加新标签
  }
  queryForm.pageNum = 1
  courseList.value = []
  console.log(queryForm)
  getList()
}

// 重置标签选择
function resetTag() {
  courseList.value = []
  queryForm.tagIdList = []
  queryForm.pageNum = 1
  getList()
}

// 获取课程列表
function getList() {
  loading.value = true
  courseListApi(queryForm)
    .then(res => {
      // 列表数据
      courseList.value = res.data.list
      // 总页数
      total.value = res.data.total
      loading.value = false
    })
    .catch(() => {
      loading.value = false
    })
}

function handleOrder(key) {
  console.log(key)
  queryForm.orderByCreateTime = key
  queryForm.pageNum = 1
  let newUrl =
    window.location.pathname +
    '?pageNum=' +
    queryForm.pageNum +
    '&name=' +
    queryForm.name +
    '&categoryId=' +
    queryForm.categoryId +
    '&orderByCreateTime=' +
    queryForm.orderByCreateTime
  window.history.pushState(
    { orderByCreateTime: queryForm.orderByCreateTime },
    'orderByCreateTime' + queryForm.orderByCreateTime,
    newUrl
  )
  getList()
}

// 搜索关键字
function handleSearch(keyword) {
  queryForm.name = keyword
  queryForm.pageNum = 1
  let newUrl =
    window.location.pathname +
    '?pageNum=' +
    queryForm.pageNum +
    '&name=' +
    queryForm.name +
    '&categoryId=' +
    queryForm.categoryId +
    '&orderByCreateTime=' +
    queryForm.orderByCreateTime
  window.history.pushState({ name: keyword }, 'name' + keyword, newUrl)
  getList()
}

function handleCurrentChange(currentPage) {
  queryForm.pageNum = currentPage
  // console.log('?!!!!', currentPage)
  // 切换路由把参数传到地址栏里
  let newUrl =
    window.location.pathname +
    '?pageNum=' +
    queryForm.pageNum +
    '&name=' +
    queryForm.name +
    '&categoryId=' +
    queryForm.categoryId +
    '&orderByCreateTime=' +
    queryForm.orderByCreateTime
  window.history.pushState(
    { pageNum: queryForm.pageNum },
    'pageNum' + queryForm.pageNum,
    newUrl
  )
  getList()
}

function toInfo(item) {
  router.push('/course/courseInfo/' + item.id)
}
</script>

<style scoped lang="scss">
.class-item {
  padding: 7px 24px;
  background-color: #f3f3f3;
  color: #757576;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;

  &:hover {
    background-color: #f0f6ff;
    color: #166af7;
  }
}

.active-tag {
  color: #fff;
  background-color: #166af7;
}

:deep(.el-input-group__append) {
  background-color: #0060ef;
  color: #fff;
  cursor: pointer;
}
</style>
