import { createRouter, createWebHistory } from 'vue-router'
import constantRoutes from './constant-routes'
import { useUserStore } from '@/store/modules/user'
import { useTagsViewStore } from '@/store/modules/tags-view'
import home from './modules/home'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { ElMessageBox } from 'element-plus'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

export const allRoutes = constantRoutes.concat(home)
const router = createRouter({
  history: createWebHistory('/'),
  routes: allRoutes,
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

// 路由白名单
const whiteRoutes = ['/dashboard']
router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.title) {
    const userStore = useUserStore()
    if (userStore.agentInfo.id == import.meta.env.VITE_APP_AGENT_ID_HONGDE) {
      if (to.path == '/dashboard') {
        document.title =
          userStore.agentInfo.agentName + '-' + '建筑行业职业技能服务平台'
      } else {
        document.title = to.meta.title + '-' + '建筑行业职业技能服务平台'
      }
    } else {
      document.title = to.meta.title + '-' + '建筑行业职业技能服务平台'
    }
  }
  // NProgress.start()
  const userStore = useUserStore()
  if (whiteRoutes.includes(to.path)) {
    if (userStore.token) {
      await userStore.initUserInfo()
    }
    next()
  } else {
    // 效验是否登录
    if (userStore.token) {
      await userStore.initUserInfo()
      if (userStore.userInfo && userStore.userInfo.authStatus != 1) {
        // 未认证
        const whitePage = ['/user/myVerCard', '/classHourSearch']
        if (whitePage.includes(to.path)) {
          ElMessageBox.confirm(
            '您未实名认证，请先实名认证后再访问该页面',
            '提示',
            {
              confirmButtonText: '去认证',
              cancelButtonText: '取消',
              type: 'warning',
            }
          ).then(() => {
            router.push('/user/userInfo')
          })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      NProgress.done()
      userStore.setShowLogin(false)
      next()
    }
  }
})
export default router
