<template>
  <div class="common-layout">
    <common-main />
    <Transition name="fade">
      <LightLogin
        :type="loginType"
        v-if="userStore.getShowLoginStatus"
      ></LightLogin>
    </Transition>
  </div>
</template>

<script setup>
import { useUserStore } from '@/store/modules/user'
const userStore = useUserStore()
const loginType = ref('pwd')
</script>

<style scoped lang="scss">
.common-layout {
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(241, 244, 255, 1);
}
</style>
