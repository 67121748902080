/**
 * @description 效验手机号
 * @param { String } mobile
 * @return { Boolean }
 */
export function isMobile(mobile) {
  const reg = /^1[3456789]\d{9}$/
  return reg.test(mobile)
}
/**
 * @description 效验登录密码（6-15位
 * @param { String } password
 * @return { Boolean }
 */
export function isPassword(password) {
  const reg = /^.{6,14}$/
  return reg.test(password)
}
/**
 * @description 效验6位验证码
 * @param { String } code
 * @return { Boolean }
 */
export function isCode(code) {
  const reg = /^[0-9]{6}$/
  return reg.test(code)
}
/**
 * @description 效验身份证
 * @param { String } identity
 * @return { Boolean }
 */
export function isIdentity(identity) {
  const reg =
    /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dxX]$/
  return reg.test(identity)
}
