import Layout from '@/layout/index.vue'
import videoLayout from '@/layout/videoLayout.vue'
import user from './user'
import studyCenter from './studyCenter'
import exam from './exam'
import order from './order'
import classHour from '@/views/home/classHour.vue'
import classHourPrint from '@/views/home/classHourPrint.vue'
import batchBuy from '@/views/home/batchBuy.vue'
import course from '@/views/course/index.vue'
import courseInfo from '@/views/course/courseInfo.vue'
import hongDeInformation from '@/views/news/hongDeInfomation.vue'
import hongDeInformationDetail from '@/views/news/informationDetail.vue'
export default [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/dashboard',
    authKey: 'back_home',
    meta: {
      title: '行企通',
      icon: 'dashboard',
      demoTitle: '建筑行业职业技能服务平台',
    },
    children: [
      ...user,
      ...studyCenter,
      ...exam,
      ...order,
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '行企通',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/course',
        name: 'course',
        component: course,
        meta: {
          title: '课程中心',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/course/courseInfo/:id',
        name: 'courseInfo',
        component: courseInfo,
        meta: {
          title: '课程详情',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/newsList',
        name: 'newsList',
        component: () => import('@/views/home/newsList.vue'),
        meta: {
          title: '通知及帮助列表',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/newsInfo',
        name: 'newsInfo',
        component: () => import('@/views/home/newsInfo.vue'),
        meta: {
          title: '通知及帮助详情',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/classHourSearch',
        name: 'classHourSearch',
        component: () => import('@/views/home/classHourSearch.vue'),
        meta: {
          title: '学时查询',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/classHourDetail',
        name: 'classHourDetail',
        component: () => import('@/views/home/classHourDetail.vue'),
        meta: {
          title: '学时详情',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/classHour',
        name: 'classHour',
        // component: () => import('@/views/home/classHour.vue'),
        component: classHour,
        meta: {
          title: '学时列表',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/classHourPrint',
        name: 'classHourPrint',
        component: classHourPrint,
        meta: {
          title: '学时打印',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/batchBuy',
        name: 'batchBuy',
        component: batchBuy,
        meta: {
          title: '批量购买',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        path: '/information',
        name: 'information',
        component: hongDeInformation,
      },
      {
        path: '/informationDetail',
        name: 'informationDetail',
        component: hongDeInformationDetail,
      },
    ],
  },
  {
    path: '/',
    name: 'vid',
    component: videoLayout,
    redirect: '/video',
    children: [
      {
        path: 'video',
        name: 'video',
        component: () => import('@/views/studyCenter/video.vue'),
        meta: {
          title: '视频',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        //考试系统
        path: 'exam/DuringTheExam',
        name: 'DuringTheExam',
        component: () => import('@/views/exam/DuringTheExam.vue'),
        meta: {
          title: '开始考试',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
      {
        //考试系统
        path: 'exam/ExamResults',
        name: 'ExamResults',
        component: () => import('@/views/exam/ExamResults.vue'),
        meta: {
          title: '考试结果',
          affix: true,
          demoTitle: '建筑行业职业技能服务平台',
        },
      },
    ],
  },
]
