<template>
  <div>
    <div class="title flex justify-between items-center"
      >绑定手机号
      <el-button type="primary" link @click="emit('changeLoginType', 'weChat')"
        >返回扫码登录</el-button
      >
    </div>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" size="large">
      <el-form-item prop="phone">
        <el-input
          v-model="ruleForm.phone"
          placeholder="请输入手机号码"
          autocomplete="off"
          maxlength="11"
          prefix-icon="iphone"
          :disabled="setPwdState"
        />
      </el-form-item>
      <el-form-item v-if="!setPwdState" prop="code">
        <el-input
          v-model="ruleForm.code"
          autocomplete="off"
          placeholder="请输入验证码"
          maxlength="6"
          prefix-icon="Lock"
        >
          <template #suffix>
            <el-button
              v-if="time == 60"
              type="primary"
              :loading="btnLoading"
              link
              @click="getCode"
              >获取验证码</el-button
            >
            <span v-else class="color-#333 fs-14"
              >{{ time }}s后重新获验证码</span
            >
          </template>
        </el-input>
      </el-form-item>
      <template v-else>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            type="password"
            show-password
            autocomplete="off"
            size="large"
            placeholder="请输入6-15位数字或字母密码"
            maxlength="15"
            prefix-icon="Lock"
          />
        </el-form-item>
        <el-form-item prop="enterpriseName">
          <el-input
            v-model="ruleForm.enterpriseName"
            placeholder="请输入企业名称"
            autocomplete="off"
            maxlength="50"
            prefix-icon="OfficeBuilding"
          />
        </el-form-item>
      </template>
    </el-form>
    <el-button
      v-if="!setPwdState"
      class="w-full"
      color="#176EF7"
      type="primary"
      size="large"
      @click="submitForm(ruleFormRef)"
      >确定绑定</el-button
    >
    <el-button
      v-else
      class="w-full"
      color="#176EF7"
      type="primary"
      size="large"
      @click="handleSetPwd(ruleFormRef)"
      >绑定</el-button
    >
  </div>
</template>

<script setup>
import { isMobile, isPassword, isCode } from '@/utils/validate'
import { bindPhoneApi, setPwdApi, sendSmsApi } from '@/api/user.js'
import { useUserStore } from '@/store/modules/user'
const emit = defineEmits(['changeLoginType'])
const userStore = useUserStore()
const props = defineProps({
  userId: {
    type: String,
    default: null,
  },
  setPwd: {
    type: String,
    default: null,
  },
})
const setPwdState = ref(false)
onMounted(() => {
  console.log('userId', props.userId)
  console.log(props.setPwd)
  if (props.setPwd) {
    setPwdState.value = true
    ruleForm.value.phone = props.setPwd
  }
})
const ruleFormRef = ref()
// 表单数据
const ruleForm = ref({
  phone: '',
  code: '',
  password: '',
  enterpriseName: '',
  scene: 'login',
})

// 表单校验规则
const rules = {
  phone: [
    {
      required: true,
      message: '请输入手机号码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isMobile(value)
      },
      trigger: 'blur',
      message: '请输入正确的手机号码',
    },
  ],
  code: [
    {
      required: true,
      message: '请输入短信验证码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isCode(value)
      },
      trigger: 'blur',
      message: '请输入6位验证码',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isPassword(value)
      },
      trigger: 'blur',
      message: '请输入6-15位密码',
    },
  ],
  enterpriseName: [
    {
      required: true,
      message: '请输入企业名称',
      trigger: 'blur',
    },
  ],
}
const time = ref(60)
const timer = ref(null)
const btnLoading = ref(false)
function getCode() {
  if (timer.value) return
  if (!ruleFormRef.value) return
  try {
    ruleFormRef.value.validateField('phone', async errorsRes => {
      if (!errorsRes) return
      btnLoading.value = true
      await sendSmsApi(ruleForm.value)
      startCountdown()
    })
  } catch (error) {
    btnLoading.value = false
  }
}
// 倒计时
function startCountdown() {
  timer.value = setInterval(function () {
    time.value--
    if (time.value === 0) {
      clearInterval(timer.value)
      timer.value = null
      time.value = 60
      btnLoading.value = false
    }
  }, 1000)
}
// 绑定
const submitForm = formEl => {
  if (!formEl) return
  formEl.validate(async valid => {
    if (valid) {
      try {
        ruleForm.value.employeeUserId = props.userId
        const { code, data } = await bindPhoneApi(ruleForm.value)
        // userStore.setToken(data.token)
        // userStore.setShowLogin(false)
        console.log(code, data)
        ruleForm.value.employeeUserId = data
        if (code == 1000) {
          setPwdState.value = true
        }
      } catch (error) {
        setPwdState.value = false
      }
    } else {
      return false
    }
  })
}

// 设置密码
const handleSetPwd = formEl => {
  if (!formEl) return
  formEl.validate(async valid => {
    if (valid) {
      try {
        if (props.setPwd) {
          // 绑定手机号，但未设置密码时
          ruleForm.value.employeeUserId = props.userId
        }

        const { data } = await setPwdApi(ruleForm.value)
        // if (data.isSetPassword) {
        //   console.log('已经---设置密码')
        // } else {
        //   console.log('没设置密码')
        // }
        userStore.setToken(data)
        userStore.setShowLogin(false)
      } catch (error) {}
    } else {
      return false
    }
  })
}
</script>

<style scoped lang="scss">
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
