import type {RouteRecordRaw} from 'vue-router'

const constantRoutes: Array<RouteRecordRaw> = [
    {
        path: '/:path(.*)',
        name: '404',
        component: () => import('@/views/error/404.vue'),
        meta: {
            noHistory: true,
            noCheck: true,
            demoTitle: '建筑行业职业技能服务平台'
        },
    },
    {
        path: '/redirect/:path(.*)',
        name: 'redirect',
        component: () => import('@/views/redirect/index.vue'),
        meta: {
            noCheck: true,
            demoTitle: '建筑行业职业技能服务平台'
        }
    }
]
export default constantRoutes
